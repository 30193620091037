<template>
  <div>
    <van-cell-group  inset style="margin-top: 10px;padding: 10px;">
  <van-cell style="background-color: #DDEFFF" title="户主" :value="item.username" />
  <van-cell title="户号" :value="item.consumer_no" />
  <van-cell title="水表号" :value="item.water_number" />
  <van-cell title="手机号" :value="item.phone" />
  <van-cell title="村委" :value="item.region_name" />
  <van-cell title="地址" :value="item.address" />
  <!-- <van-cell title="水表位置" :value="item.place" /> -->
  <van-cell title="上次抄表" :value="`${item.records.last_ton}吨`" />
  <van-cell title="上次抄表时间" :value="item.records.lasttime" />
  <van-cell title="本次抄表" :value="`${item.records.ton}吨`" />
  <van-cell title="本次抄表时间" :value="item.records.createtime" />
  <van-cell title="用水量" :value="`${item.records.use_ton}吨`" />
  <van-cell title="实付金额" :value="`${item.amount}元`" />
  <van-cell title="支付状态" :value="item.status==='wait'?'待支付':'已经支付'" />

  <div style="margin:0px 16px 0px 16px;">
    <h1 v-if="item.status==='wait'">
      <van-row>
  <van-col span="12"><van-button  type="info" size="small" block  style="margin: 0 10px 0 0;"  @click="$router.push(`/confirmorder/from/order/records_id/${item.records.id}/order_id/${item.id}`)" >去支付</van-button></van-col>
  <van-col span="12"><van-button  type="info" size="small" block  style="margin: 0 0 0 10px;" @click="$router.push(`/orderinfo/records_id/${item.records.id}/order_id/${item.id}`)" >查看详情</van-button></van-col>
</van-row>
    </h1>
  <h1 v-else><van-button  type="info" size="small" block  @click="$router.push(`/orderinfo/records_id/${item.records.id}/order_id/${item.id}`)" >查看详情</van-button></h1>

</div>
</van-cell-group>

  </div>

</template>

<script>
export default {
  name: 'OrderItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
