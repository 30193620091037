<template>
  <div>
    <van-cell-group inset>
      <van-cell>
        <template #label
          ><div style=" font-weight: bold">
            {{item.paytype}}  水量：{{item.use_ton}}吨 上次抄表:{{ item.last_ton }} 本次抄表:{{ item.ton }}
          </div></template
        >
        <template #title
          ><div style="color: #1989fa; font-weight: bold; font-size: large">
            {{ item.paytime }}
            <span style="color: #969799; font-size: small"
              >水费：{{item.waterbill}}元</span
            >
            <span style="color: #969799; font-size: small"
              > 实缴：{{item.amount}}元</span
            >
          </div></template
        >
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'BillItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
</style>
