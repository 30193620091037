import Vue from 'vue'
import {
  Button, Rate, Tabbar, NavBar, TabbarItem, Form, Field, Toast, Cell, List, Icon,
  Grid,
  GridItem,
  CellGroup
  , Skeleton
  , Image as VanImage
  , Lazyload
  , Col, Row
  , Swipe, SwipeItem
  , NoticeBar
  , Search
  , Uploader
  , Picker
  , Popup
  , Dialog
  , Overlay
  , Panel
  , DropdownMenu, DropdownItem
  , Calendar
  , Collapse, CollapseItem
  , Tab, Tabs
  , Divider
  , Tag
  , CountDown
  , RadioGroup, Radio,
  Switch
} from 'vant'

Vue.use(Switch)
Vue.use(Radio)
Vue.use(RadioGroup)

Vue.use(Grid)
Vue.use(GridItem)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(Form)
Vue.use(Field)
Vue.use(Tabbar)
Vue.use(NavBar)
Vue.use(TabbarItem)
Vue.use(Button)
Vue.use(Rate)
Vue.use(Cell)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Skeleton)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Col)
Vue.use(Row)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(NoticeBar)
Vue.use(Search)
Vue.use(Uploader)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Overlay)
Vue.use(Panel)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Calendar)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Divider)
Vue.use(Tag)

Vue.use(CountDown)
