const KEY = 'hmmj-vant-h5-90-token'
export const setToken = token => localStorage.setItem(KEY, token)
export const getToken = () => localStorage.getItem(KEY)
export const delToken = () => localStorage.removeItem(KEY)
const OPENIDKEY = 'hmmj-vant-h5-90-openid'
export const setOpenid = openid => localStorage.setItem(OPENIDKEY, openid)
export const getOpenid = () => localStorage.getItem(OPENIDKEY)
export const delOpenid = () => localStorage.removeItem(OPENIDKEY)
const URLKEY = 'hmmj-vant-h5-90-url'
export const setUrl = url => localStorage.setItem(URLKEY, url)
export const getUrl = () => localStorage.getItem(URLKEY)
export const delUrl = () => localStorage.removeItem(URLKEY)
