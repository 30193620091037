<template>
  <div>
    <van-cell-group inset>
      <van-cell>
        <template #label
          ><div style="color: #1989fa; font-weight: bold">
            {{item.paytype}}
          </div></template
        >
        <template #title
          ><div style="color: #1989fa; font-weight: bold; font-size: large">
            {{ item.paytime }}
            <span style="color: #969799; font-size: medium"
              >实缴：{{item.amount}}元 </span
            >
          </div></template
        >
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'PayLogItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
</style>
