import request from '@/utils/request'
export const register = (data) => {
  return request.post('/api/user/registeruser', data)
}

export const login = data => request.post('/api/user/login', data)
export const bindstaff = data => request.post('/api/user/bindstaff', data)
export const bindConsumer = data => request.post('/api/user/bindconsumer', data)
export const bindConsumers = data => request.post('/api/user/bindconsumers', data)

export const getUserInfo = () => { return request('/api/user/getinfo') }
export const getBindstaff = () => { return request('/api/user/bindstaff') }
export const getConsumer = () => { return request('/api/user/getconsumer') }
export const getConsumers = () => { return request('/api/user/getconsumers') }
export const getRegion = () => { return request('/api/user/getregion') }
export const getPlant = () => { return request('/api/user/getplant') }
export const getPlantlist = () => { return request('/api/user/plantlist') }
export const changConsumer = data => request.post('/api/user/changconsumer', data)
export const collect = data => request.post('/api/user/collect', data)
export const repair = data => request.post('/api/user/repair', data)
export const consumerrepair = data => request.post('/api/user/consumerrepair', data)
export const getIsClock = () => { return request('/api/user/isclock') }
export const clock = data => request.post('/api/user/clock', data)
export const getclock = data => request.post('/api/user/getclock', data)
export const getnotice = data => request.post('/api/user/getnotice', data)
export const setPlant = data => request.post('/api/user/setplant', data)
