<template>
  <div>
    <van-cell-group  inset style="margin-top: 10px;padding: 10px;">
  <van-cell style="background-color: #DDEFFF" title="户主" :value="username" />
  <van-cell title="户号" :value="item.consumer_no" />
  <van-cell title="水表号" :value="water_number" />
  <van-cell title="手机号" :value="phone" />
  <div style="margin:0px 16px 0px 16px;">
    <van-row>
  <!-- <van-col v-if="this.itemphone==''"  span="12"><van-button  type="info" size="small" block  @click="$router.push(`/records/${item.id}`)" >去抄表</van-button></van-col>
  <van-col v-if="this.itemphone!==''" span="24"><van-button  type="info" size="small" block  @click="$router.push(`/records/${item.id}`)" >去抄表</van-button></van-col>
  <van-col v-if="this.itemphone==''" span="12">  <van-button  type="primary" size="small" block  @click="sss" >修改资料</van-button></van-col> -->

  <van-col  span="24"> <van-button :disabled="d"  type="primary" size="small" block :text="t"  @click="sss" ></van-button></van-col>

</van-row>

</div>
</van-cell-group>

  </div>

</template>

<script>

import { bindConsumers } from '@/api/user'
export default {
  name: 'ConsumerItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      consumer_id: this.item.id,
      show: false,
      phone: this.item.phone,
      username: this.item.username,
      water_number: this.item.water_number,
      card: this.item.card,
      d: this.item.user_id > 0,
      t: this.item.user_id > 0 ? '已绑定' : '绑定'
    }
  },
  methods: {
    // dialog () {
    //   this.$dialog.confirm({
    //     title: '用主',
    //     message: this.username
    //   })
    //     .then(() => {
    //       alert(this.consumer_id)
    //     })
    //     .catch(() => {
    //       // on cancel
    //     })
    // },

    async sss () {
      const res = await bindConsumers({
        consumer_id: this.item.id
      })
      // console.log(res)
      try {
        if (res.code === 1) {
          // console.log(res)
          this.$toast.success(res.msg)
          this.$router.push('/cons')
        }
        this.$toast.fail(res.msg)
      } catch (e) {
        this.$toast.fail(e.response.message)
      }
    }
  }
}
</script>

<style>

</style>
