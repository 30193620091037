import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/utils/vant-ui'
import BaiduMap from 'vue-baidu-map'
import ConsumerItem from '@/components/ConsumerItem'
import ConsumerItems from '@/components/ConsumerItems'
import RecordslistItem from '@/components/RecordslistItem'
import CorderlistItem from '@/components/CorderlistItem'
import OrderItem from '@/components/OrderItem'
import PayLogItem from '@/components/PayLogItem'
import BillItem from '@/components/BillItem'
import QbillItem from '@/components/QbillItem'

Vue.component('ConsumerItem', ConsumerItem)
Vue.component('ConsumerItems', ConsumerItems)
Vue.component('RecordslistItem', RecordslistItem)
Vue.component('CorderlistItem', CorderlistItem)
Vue.component('OrderItem', OrderItem)
Vue.component('PayLogItem', PayLogItem)
Vue.component('BillItem', BillItem)
Vue.component('QbillItem', QbillItem)
Vue.component('PaperlistItem', function () { return import('@/components/PaperlistItem') })

Vue.use(BaiduMap, {

  ak: 'bzPvMGtp8d5khAzBBH26UYBsaGyX0mhs'
})

Vue.config.productionTip = false
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
